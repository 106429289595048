#react-app-container {
  height: 100%;
  min-height: 650px;
}

.react-chatbot-kit-user-chat-message-container {
  display: flex;
  margin: 15px 0;
  justify-content: flex-end;
}

.react-chatbot-kit-user-avatar-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 12.5px;
  background-color: #3d4e8d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-chatbot-kit-user-avatar-icon {
  fill: #fff;
  width: 15px;
  height: 15px;
}

.react-chatbot-kit-user-chat-message {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #585858;
  font-weight: medium;
  position: relative;
  text-align: left;
}

.react-chatbot-kit-user-chat-message-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #f1f1f1;
  position: absolute;
  right: -7px;
  top: 13px;
}

.react-chatbot-kit-chat-bot-message-container {
  display: flex;
  margin: 15px 0;
  justify-content: flex-start;
  max-width: calc(100% - 100px);
}

.react-chatbot-kit-chat-bot-message-container p, .react-chatbot-kit-user-chat-message-container p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1.2rem;
  margin-right: 12.5px;
  background-color: #d8d8d8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-chatbot-kit-chat-bot-avatar-icon {
  fill: #494646;
  width: 22.5px;
  height: 22.5px;
}

.react-chatbot-kit-chat-bot-avatar-letter {
  color: #1d1d1d;
  margin: 0;
  padding: 0;
}

.react-chatbot-kit-chat-bot-message {
  background-color: #7186a3;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #fff;
  font-weight: medium;
  position: relative;
  text-align: left;
}

.react-chatbot-kit-chat-bot-message-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #7186a3;
  position: absolute;
  left: -7px;
  top: 13px;
}

.react-chatbot-kit-chat-bot-loading-icon-container {
  height: 17px;
  width: 25px;
}

.chatbot-loader-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

#chatbot-loader #chatbot-loader-dot1 {
  animation: load 1s infinite;
}

#chatbot-loader #chatbot-loader-dot2 {
  animation: load 1s infinite;
  animation-delay: 0.2s;
}

#chatbot-loader #chatbot-loader-dot3 {
  animation: load 1s infinite;
  animation-delay: 0.4s;
}

@keyframes load {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.react-chatbot-kit-chat-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 650px;
}

.react-chatbot-kit-chat-inner-container {
  height: calc(100% - 50px);
  min-height: 600px;
  background-color: #fff;
}

.react-chatbot-kit-chat-header {
  height: 15px;
  background-color: #efefef;
  font-family: Arial;
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  color: #514f4f;
  padding: 12.5px;
  font-weight: bold;
  padding-left: 40px;
}

.react-chatbot-kit-chat-input-container {
  height: 50px;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
}

.react-chatbot-kit-chat-message-container {
  padding: 0 17.5px 0px 17.5px;
  overflow-y: scroll;
  height: calc(100% - 40px);
  min-height: 510px;
}

.react-chatbot-kit-chat-input {
  width: 100%;
  padding: 12.5px;
  border: none;
  font-size: 0.85rem;
  border-top: 1px solid #d8d8d8;
  border-bottom-left-radius: 5px;
}

.react-chatbot-kit-chat-input-form {
  width: 100%;
  display: flex;
}

.react-chatbot-kit-chat-input::placeholder {
  color: #585858;
}

.react-chatbot-kit-chat-btn-send {
  background-color: #7186a3;
  width: 100px;
  border: none;
  color: #fff;
}

.react-chatbot-kit-chat-btn-send:disabled {
  background-color: #b0b8c6;
  cursor: not-allowed;
}

.react-chatbot-kit-chat-btn-send-icon {
  fill: #fff;
  width: 15px;
  margin: 0 auto;
}

.react-chatbot-kit-error {
  background-color: #fff;
  border-radius: 3px;
  padding: 15px;
}

.react-chatbot-kit-error-container {
  width: 260px;
}

.react-chatbot-kit-error-header {
  font-size: 1.3rem;
  color: #1d1d1d;
  margin-bottom: 30px;
}

.react-chatbot-kit-error-docs {
  display: block;
  margin: 25px auto;
  color: rgb(56, 104, 139);
  padding: 8px;
  border: 1px solid rgb(40, 152, 236);
  width: 130px;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
}

/* AI-GEN START - Cursor */
.react-chatbot-kit-chat-bot-message h1 {
  font-size: 1.2rem;
}

.react-chatbot-kit-chat-bot-message h2 {
  font-size: 1.1rem;
}

.react-chatbot-kit-chat-bot-message h3 {
  font-size: 1rem;
}

.react-chatbot-kit-chat-bot-message h4 {
  font-size: 0.9rem;
}


.react-chatbot-kit-chat-bot-message code {
  padding: 2px 4px;
  font-size: 90%;
  color: #d8d8d8;
  background-color: rgb(56, 104, 139);
  border-radius: 4px;
}

/* AI-GEN END */

/* AI-GEN START - ChatGPT */
.thinking {
  position: relative;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 20px;
  font-style: italic;
  color: #555;
  max-width: 200px;
  margin: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: inline-block;
}

.thinking::before {
  content: '';
  position: absolute;
  top: 100%;
  width: 0px;
  height: 0px;
  border-style: solid;
}

.thinking::before {
  left: 50%;
  border-width: 8px 8px 0 8px;
  border-color: #f0f0f0 transparent transparent transparent;
}

@keyframes thinkingAnimation {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.thinking {
  animation: thinkingAnimation 1.5s ease-in-out infinite;
}

/* AI-GEN END */

/* AI-GEN START - Claude */
/* Spinner Container */
.tool-calling {
  position: relative;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 20px;
  font-style: italic;
  color: #555;
  max-width: 300px;
  margin: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  animation: thinkingAnimation 1.5s ease-in-out infinite;
}

.tool-calling::before {
  content: '';
  position: absolute;
  top: 100%;
  width: 0px;
  height: 0px;
  border-style: solid;
}

.tool-calling::before {
  left: 50%;
  border-width: 8px 8px 0 8px;
  border-color: #f0f0f0 transparent transparent transparent;
}

/* Spinner Animation */
.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #555;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 8px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* AI-GEN END */

/* AI-GEN START - Cursor */
:root {
  --sidebar-width: 210px;
}

#sidebar {
  width: 0px;
  /* Hide sidebar by default */
  top: 0;
  background-color: #efefef;
  transition: 0.3s;
  padding-top: 40px;
}

#sidebar.open {
  /* Show sidebar when open */
  width: var(--sidebar-width);
}

#sidebar a {
  padding: 10px 15px;
  width: var(--sidebar-width);
  text-decoration: none;
  font-size: 0.8rem;
  color: #333;
  display: block;
  transition: 0.3s;
  white-space: nowrap;
  /* AI-GEN - Cursor */
  overflow: hidden;
  /* AI-GEN - Cursor */
  text-overflow: ellipsis;
  /* AI-GEN - Cursor */
  border-bottom: 1px solid #ddd;
  /* AI-GEN - Cursor */
}

#sidebar a:hover {
  background-color: #ddd;
}

#sidebar-toggle {
  position: relative;
  width: 0px;
  top: 0px;
  left: 10px;
  /* Adjusted to ensure visibility */
  font-size: 20px;
  line-height: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 1000;
  /* Ensure it is above other elements */
}

.new-chat-btn {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-size: 0.9rem;
  background-color: #5A67D8;
  color: white;
  border: none;
  cursor: pointer;
  width: 100%;
  min-width: var(--sidebar-width);
  /* AI-GEN - Cursor */
  transition: background-color 0.3s;
}

.new-chat-btn:disabled {
  cursor: not-allowed;
  background-color: #b0b8c6;
}

.new-chat-btn:disabled:hover {
  background-color: #b0b8c6;
}

.new-chat-btn:hover {
  background-color: #434190;
}

.new-chat-btn svg {
  margin-right: 8px;
}

.pagination {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: calc(var(--sidebar-width) - 30px);
  padding-top: 20px;
}

.pagination button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.0rem;
}

.pagination span {
  font-size: 0.9rem;
}

.session-item {
  background: none;
  border: none;
  padding: 10px 15px;
  text-align: left;
  font-size: 0.9rem;
  color: #333;
  display: block;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s;
  min-width: calc(var(--sidebar-width) - 30px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2px;
}

.session-item.active {
  background-color: #c5c5c5; 
  color: black;
  font-weight: 600;
}

.session-item.loading {
  pointer-events: none;
}

.session-item:hover {
  background-color: #ddd;
}

.session-item .icon {
  visibility: hidden;
}

.session-item:hover .icon {
  visibility: visible;
}

.load-more {
  font-weight: 700;
}
/* AI-GEN END */